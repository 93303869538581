<template>
  <div id="app">
    <LandingPage v-if="!maintenanceMode" />  

    <div class="maintenance-page" v-else>
      <pre class="console-output">{{ maintenanceText }}</pre>
      <div class="console-input">
        <input type="text" v-model="consoleText" autofocus />
      </div>
      <img alt="Stencil watermark" src="./assets/headtrans.png" class="bottom-image">
    </div>
  </div>
</template>

<script>
import LandingPage from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    LandingPage
  },
  data() {
    return {
      maintenanceMode: true,
      maintenanceText: 'There is no spoon',
      consoleText: '',
    }
  }
}
</script>

<style>
#app {
  @font-face {
    font-family: 'Open Sans';
    src: url('~@/assets/OpenSans-Regular.ttf') format('truetype');
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.maintenance-page {
  position: relative;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottom-image {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  max-height: 26.67vh;
  object-fit: contain;
}

.console-output {
  font-family: 'Courier New', monospace; 
  font-size: 1em;
  color: #d9d9d9; 
  padding: 10px;
  border-radius: 5px;
}

.console-input {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
}

.console-input input {
  font-family: 'Courier New', monospace;
  font-size: 1em;
  background: transparent;
  border: none;
  outline: none;
  color: #d9d9d9;
  width: 100%;
  max-width: 60%;
  line-height: 1.5em;
  padding: 5px;
}

.console-input input::after {
  content: '|';
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to { opacity: 1 }
  50% { opacity: 0 }
}
</style>
