<template>
  <div class="hello">
    <maintitle>{{ name }}</maintitle>
  </div>
</template>

<script>
export default {
  name: 'LandingPage', // More descriptive name
  data () {
    return {
      name: 'Willy'
    }
  }
}
</script>

<style scoped>

maintitle {
  margin-top: 20%;
  font-weight: bolder;
  font-size: 2em;
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}


</style>